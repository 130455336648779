import React from "react";

const InquryMap = () => {
  return (
    <div>
      <div className="section m-top-20px">
        <div className="container">
          <div className="inquiry-map-wrapper">
            <div className="heading">Our Offices</div>
            <div className="locations-wrapper m-top-20px">
              <div className="location">
                <div className="heading color-en">USA</div>
                <div className="para">
                  5100 Parkcenter Avenue, <br /> Dublin, Ohio 43017, <br />{" "}
                  United States
                  <br></br>
                  <br></br>
                  (614)-495-8100
                </div>
              </div>
              <div className="location">
                <div className="heading color-in">INDIA</div>
                <div className="para">
                  4th Floor, Anmol Pride Bldg,
                  <br />
                  Behind RBL Bank, Baner Road
                  <br />
                  Baner, Pune - 411045 <br /> Maharashtra, India
                  <br></br>
                  <br></br>
                  +91 8605617222
                </div>
              </div>
              <div className="location">
                <div className="heading color-ja">JAPAN</div>
                <div className="para">
                  Tsunashima No. 8 101 2-5-8 Tsunashima- nishi, Kohoku-ku,
                  Yokohama 223-0053, Japan
                  <br></br>
                  <br></br>
                  045-516-2928
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default InquryMap;
